import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

import _, { lte, set } from 'lodash';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { Tooltip, Input, IconButton, Box, Collapse, Typography } from '@mui/material';

import { map, distinctUntilChanged, tap, filter, pairwise } from 'rxjs/operators';

import ChartPage from './Chart';
import SouthEastRoundedIcon from '@mui/icons-material/SouthEastRounded';
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import moment from "moment";

import { PairsDataContext } from "./PairDataProvider";
import { act } from "react-dom/test-utils";

export function compareObjects(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

function requestNotificationPermission() {

  if ('Notification' in window) {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // Now you can show notifications
      } else {
        console.log("Notification permission denied.");
      }
    });
  }
}

function generateRandom9DigitNumber() {
  // Generate a number between 100000000 (inclusive) and 1000000000 (exclusive),
  // ensuring it's always 9 digits.
  const randomNumber = Math.floor(Math.random() * (1000000000 - 100000000)) + 100000000;
  return randomNumber;
}

function showNotification(pair: string, body: string) {
  if ('Notification' in window && Notification.permission === "granted") {
    const notification = new Notification(pair, {
      body,
      // Optional properties:
      icon: "/path/to/icon.png",
      requireInteraction: true,
      tag: "unique-tag-" + generateRandom9DigitNumber(), // Helps prevent duplicate notifications
      // There are other properties and options available as well.
    });

    // Optionally, handle click events on the notification
    notification.onclick = function () {
      window.open("http://localhost:3000/");
    };
  }
}

export function formatMoney(amount: string): string {
  if (!amount) return ''
  return "$" + parseInt(amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getDirectionIcon(direction: string, delayed: boolean = false) {

  if (direction === 'up') {
    if (delayed) {
      return <div className="pillow orange">-</div>
    } else {
      return <div className="pillow green"><NorthEastRoundedIcon sx={{ fontSize: 18 }} /></div>
    }
  } else if (direction === 'down') {
    if (delayed) {
      return <div className="pillow orange">-</div>
    } else {
      return <div className="pillow red"><SouthEastRoundedIcon sx={{ fontSize: 18 }} /></div>
    }
  } else {
    return <div className="pillow grey"></div>
  }
}

function getDelayedInfo(ts: number, tf: number) {

  const tsNow = moment().unix();
  const seconds = tf * 60; // allowed diff in seconds 

  const diff = tsNow - ts;

  if (diff > seconds * 2 + 30) { // because we are always counting everying for last closed candle, so 1 period behind. 30 is an arbitrary safety margin
    return true
  } else {
    return false
  }
}

// calculate using moment js how many minutes passed since a timestamp. Give diff in minutes or hours or days depending on the diff size. date should be accepted as timestamp

function minutesFromNow(ts: number) {
  const tsNow = moment().unix();
  const diff = tsNow - ts

  if (diff < 60) {
    return diff + 's'
  } else if (diff < 3600) {
    return Math.floor(diff / 60) + 'm'
  } else if (diff < 86400) {
    return Math.floor(diff / 3600) + 'h'
  } else {
    return Math.floor(diff / 86400) + 'd'
  }
}

function getAveragesTooltip(wws: any) {
  if (!wws) return ''
  const { sa, fa } = wws

  if (!sa || !fa ) return ''

  return <>
    <div>Avg closed above/below fast: {fa}%</div>
    <div>Avg closed above/below slow: {sa}%</div>
    {/* <div>Exp. avg closed above/below fast: {nsf}%</div>
    <div>Exp. avg closed above/below slow: {nss}%</div> */}
    {/* { tcc ? <div>Exp. avg closed above/below slow: {nss}%</div> : null } */}
  </>
}

function RowDetails(props: { pair: ReturnType<any> }) {
  const { pair } = props;
  const { pairs$ } = React.useContext(PairsDataContext);
  const [pairData, setPairData] = useState<any>(null);


  useEffect(() => {
    const subscription = pairs$.pipe(
      map((pairs: any) => {
        return pairs.find((p: any) => p.pair === pair)
      }),
    ).subscribe((value: any) => {
      setPairData(value)
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [pairs$]);

  if (pairData) {


    const m1TrendStrenght = JSON.parse(pairData.m1_trend_strength_json)
    const wws1m = JSON.parse(pairData.wws1m)
    const wws5m = JSON.parse(pairData.wws5m)
    const wws15m = JSON.parse(pairData.wws15m)

    function convertMinutesToHours(minutes) {

      // Use moment.js to convert the minutes to hours and minutes
      const duration = moment.duration(minutes, 'minutes');
      const hours = Math.floor(duration.asHours());
      const remainingMinutes = duration.minutes();

      // Conditional check to display only minutes if no hours
      if (hours > 24) {
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        let ret = ''
        if (days > 0) {
          ret += `${days}d `
        }
        if (remainingHours > 0) {
          ret += ` ${remainingHours}h `
        }
        if (remainingMinutes > 0) {
          ret += ` ${remainingMinutes}m`
        }

        return ret
      } else {
        return `${hours} h ${remainingMinutes} m`
      }
    }

    return <>
      <TableCell align="center">
        <div className="table-item">
          {parseFloat(pairData.price).toFixed(4)}
        </div>
      </TableCell>
      <TableCell align="center">
        {(wws1m && wws1m.ts) &&
          <Tooltip title={`Last update: ${moment.unix(wws1m.ts).format("DD-MM-YYYY HH:mm:ss").toString()}`}>
            {getDirectionIcon(wws1m.dir, getDelayedInfo(wws1m.ts, 1))}
          </Tooltip>
        }
      </TableCell>
      <TableCell align="center">
        {(wws5m && wws5m.ts) ?
          <Tooltip title={`Last update: ${moment.unix(wws5m.ts).format("DD-MM-YYYY HH:mm:ss").toString()}`}>
            {getDirectionIcon(wws5m.dir, getDelayedInfo(wws5m.ts, 5))}
          </Tooltip>
          : getDirectionIcon('down', getDelayedInfo(0, 5))
        }
      </TableCell>
      <TableCell align="center">
        {(wws15m && wws15m.ts) &&
          <Tooltip title={`Last update: ${moment.unix(wws15m.ts).format("DD-MM-YYYY HH:mm:ss").toString()}`}>
            {getDirectionIcon(wws15m.dir, getDelayedInfo(wws15m.ts, 15))}
          </Tooltip>
        }
      </TableCell>
      <TableCell align="center">
        <Tooltip title={`Last update: ${moment.unix(pairData.h1_cross_ts).format("DD-MM-YYYY HH:mm:ss").toString()}`}>
          {getDirectionIcon(pairData.h1_cross, getDelayedInfo(pairData.h1_cross_ts, 60))}
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={`Last update: ${moment.unix(pairData.h4_cross_ts).format("DD-MM-YYYY HH:mm:ss").toString()}`}>
          {getDirectionIcon(pairData.h4_cross, getDelayedInfo(pairData.h4_cross_ts, 240))}
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {formatMoney(pairData.vol_5m)}
      </TableCell>
      <TableCell align="center">
        {pairData.volatility_5m}
      </TableCell>
      <TableCell align="center">
        <Tooltip title={getAveragesTooltip(wws1m)}>
          <div>{wws1m && wws1m.dur ? convertMinutesToHours(wws1m.dur) : ''}</div>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
      <Tooltip title={getAveragesTooltip(wws5m)}>
          <div>{wws5m && wws5m.dur ? convertMinutesToHours(wws5m.dur * 5) : ''}</div>
        </Tooltip>

      </TableCell>
      <TableCell align="center">
      <Tooltip title={getAveragesTooltip(wws15m)}>
        <div>{wws15m && wws15m.dur ? convertMinutesToHours(wws15m.dur * 15) : ''}</div>
        </Tooltip>
      </TableCell>
    </>
  } else {
    return <>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="center"></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </>
  }
}

function Row(props: { pair: ReturnType<any>, activePair: string, setActivePair: any }) {
  const { pair, activePair, setActivePair } = props;
  const { pairs$ } = React.useContext(PairsDataContext);

  const pairLinkTv = `https://www.tradingview.com/chart/?symbol=BINANCE%3A${pair.replace('/', '')}.P`
  const pairLinkBinance = `https://www.binance.com/en/futures/${pair.replace('/', '')}`

  if (pair) {

    let sx = {}
    let open = false
    if (pair === activePair) {
      open = true;
      sx = { height: '500px;' }
    }

    const chartClass = open ? 'chart-container active' : 'chart-container';

    return (
      <TableRow hover role="checkbox" tabIndex={-1} id={"row- " + pair.id} sx={sx}>
        <TableCell>
          <div className="cell-content">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                if (activePair === pair) {
                  setActivePair('')
                } else {
                  setActivePair(pair)
                }
              }
              }
            >
              {open ? <KeyboardDoubleArrowUpRoundedIcon /> : <KeyboardDoubleArrowDownRoundedIcon />}
            </IconButton>

            <div className={chartClass}>
              {open && <>
                <ChartPage pair={activePair} tf="1" />
                <ChartPage pair={activePair} tf="15" />
              </>}
            </div>
          </div>
        </TableCell>
        <TableCell align="center"><a href={pairLinkTv} target="_blank">{pair}</a>&nbsp;&nbsp;&nbsp;<a href={pairLinkBinance} target="_blank">🔶</a></TableCell>
        <RowDetails pair={pair}></RowDetails>
      </TableRow>
    );
  }

  return null;
}

const WebsocketConsumer: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const { pairs$, filter, changeFilter } = React.useContext(PairsDataContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [activePair, setActivePair] = useState('');
  const tableScrollRef = useRef(null);
  const chartRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // const sortedData = React.useMemo(() => {

  //   console.log('sortconfig', sortConfig, sortConfig.key );

  //   if (sortConfig.key !== null) {

  //     console.log('HERE', sortConfig.key );

  //     return [...data].sort((a, b) => {

  //       if (a[sortConfig.key] < b[sortConfig.key]) {
  //         return sortConfig.direction === 'asc' ? -1 : 1;
  //       }
  //       if (a[sortConfig.key] > b[sortConfig.key]) {
  //         return sortConfig.direction === 'asc' ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return data;
  // }, [data, sortConfig]);

  useEffect(() => {

    const subscription = pairs$.pipe(
      map((pairs: any) => {
        console.log('MAIN LIST MAPPING', pairs);

        let pairsSorted = [...pairs]

        if (sortConfig.key !== null) {
          pairsSorted = [...pairsSorted].sort((a, b) => {

            if (sortConfig.key === 'wws1m' || sortConfig.key === 'wws5m' || sortConfig.key === 'wws15m') {
              console.log('SORT HERE',);

              console.log('VAL1', JSON.parse(a[sortConfig.key]));
              console.log('VAL2', JSON.parse(b[sortConfig.key]));
              if (!JSON.parse(a[sortConfig.key]) || !JSON.parse(b[sortConfig.key])) {
                return 0
              }

              if (JSON.parse(a[sortConfig.key]).dur < JSON.parse(b[sortConfig.key]).dur) {
                return sortConfig.direction === 'asc' ? -1 : 1;
              }
              if (JSON.parse(a[sortConfig.key]).dur > JSON.parse(b[sortConfig.key]).dur) {
                return sortConfig.direction === 'asc' ? 1 : -1;
              }

            } else {
              if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
              }
              if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
              }
            }
            return 0;
          });
        }

        return pairsSorted.map((pair: any) => pair.pair)
      }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {

      setData(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [pairs$, sortConfig.direction, sortConfig.key]);

  useEffect(() => {
    const filterItems = () => {
      console.log('FILTERING = SEARCH TERM', searchTerm);

      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      let filtered = data;
      if (lowerCaseSearchTerm.length > 0) {
        filtered = data.filter((item: any) => {
          return item.toLowerCase().includes(lowerCaseSearchTerm)
        }
        );
      }

      console.log('SET FILTERED', filtered);

      setFilteredItems(filtered);
    };

    filterItems();
  }, [searchTerm, data]);

  const handleButtonClick = () => {
    showNotification('ETH/USDT', 'ETH/USDT is going up');
  };

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  const tsNow = moment().unix();

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const requestSort = (key) => {
    console.log('KEY', key);

    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });
  };

  const getArrow = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  return (
    <>
      <div className="container">
        <header className="header">
          <div className="filters">
            <button className="filter-btn" onClick={() => { changeFilter('vol_5m > 0') }}>All volume</button>
            <button className="filter-btn" onClick={() => { changeFilter('vol_5m > 200000') }}> Vol {'>'} 200k</button>
            <button className="filter-btn" onClick={() => { changeFilter('vol_5m > 500000') }}> Vol {'>'} 500k</button>
          </div>

          <div>Filter: {filter}</div>
        </header>
        <div className="content">
          {data ? (<>
            {/* <div className="scrollable"> */}
            <div className="table-container" ref={tableScrollRef}>
              <Table stickyHeader aria-label="sticky table">
                {/* <TableHead style={{position: "sticky", zIndex: 1000}}> */}
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: 20 }}></TableCell>
                    <TableCell align="center" style={{ width: 250 }}>Pair</TableCell>
                    <TableCell align="center" style={{ minWidth: 50 }}>Price</TableCell>
                    <TableCell align="center" style={{ width: 20 }}>1min</TableCell>
                    <TableCell align="center" style={{ width: 20 }}>5min</TableCell>
                    <TableCell align="center" style={{ width: 20 }}>15min</TableCell>
                    <TableCell align="center" style={{ width: 20 }}>1h</TableCell>
                    <TableCell align="center" style={{ width: 20 }}>4h</TableCell>
                    <TableCell align="center" style={{ width: 20, cursor: 'pointer' }} onClick={() => requestSort('vol_5m')}>5m vol {getArrow('vol_5m')}</TableCell>
                    <TableCell align="center" style={{ width: 20, cursor: 'pointer' }} onClick={() => requestSort('volatility_5m')}>5m&nbsp;volatility&nbsp;{getArrow('volatility_5m')}</TableCell>
                    <TableCell align="center" style={{ minWidth: 40, cursor: 'pointer' }} onClick={() => requestSort('wws1m')}>1m trend  {getArrow('wws1m')}</TableCell>
                    <TableCell align="center" style={{ minWidth: 40, cursor: 'pointer' }} onClick={() => requestSort('wws5m')}>5m trend  {getArrow('wws5m')}</TableCell>
                    <TableCell align="center" style={{ minWidth: 40, cursor: 'pointer' }} onClick={() => requestSort('wws15m')}>15m trend  {getArrow('wws15m')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data.map((pair: any) => <Row key={pair} pair={pair} activePair={activePair} setActivePair={setActivePair} />)
                  }
                </TableBody>
              </Table>
            </div>
            {/* </div> */}
          </>
          ) : (<div>loading...</div>)
          }
        </div>
      </div>
    </>
  );
};

export default WebsocketConsumer;